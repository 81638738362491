/* You can add global styles to this file, and also import other style files */
body 
    display: grid
    width: 100vw
    height: 100vh
    grid-template-columns: minmax(150px 1fr) 2fr minmax(150px 1fr)
    background-image: url("assets/images/logobackground.svg")
    // background-color: blue
    background-position: center
    background-attachment: fixed
    background-repeat: no-repeat
    background-size: 120vh
    font-size: 1em
    // filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)

div
    width: 80vw
    margin: auto